/** @format */

import {
	get,
	post,
	// put
} from './http.js'
//get post 同时都要引入

// p = params
export const login = (p) => post('login/index', p) // 登录
export const getUserInfo = (p) => post('user/user_info', p) // 登录

export const getList = (p) => post('article/get_article_list', p) // 获取列表
export const vote = (p) => post('article/article_vote', p) // 投票
export const getDetail = (p) => post('article/get_article_by_id', p) // 分享

export const upload = (p) => post('user/add_article', p) // 上传
export const saveAddress = (p) => post('user/save_address', p) // 上传
export const get_share_url = (p) => post('user/get_share_url', p) // 分享
