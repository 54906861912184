/** @format */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import 'vant/lib/index.less' // 引入vantui组件全部样式
import {
	Toast,
	Icon,
	Overlay,
	Popup,
	List,
	Field,
	CellGroup,
	Cell,
	Uploader,
} from 'vant'
const Base64 = require('js-base64').Base64

Vue.use(CellGroup)
Vue.use(Field)
Vue.use(List)
Vue.use(Icon)
Vue.use(Overlay)
Vue.use(Toast)
Vue.use(Cell)
Vue.use(Uploader)
Vue.use(Popup)

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.config.productionTip = false
Vue.prototype.router = router
new Vue({
	router,
	Base64,
	render: (h) => h(App),
}).$mount('#app')
