/** @format */

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: (resolve) => require(['@/views/Home'], resolve),
	},
	{
		path: '/rule',
		name: 'rule',
		component: (resolve) => require(['@/views/rule'], resolve),
	},
	{
		path: '/rank',
		name: 'rank',
		component: (resolve) => require(['@/views/rank'], resolve),
	},
	{
		path: '/upload',
		name: 'upload',
		component: (resolve) => require(['@/views/upload'], resolve),
	},
	{
		path: '/detail',
		name: 'detail',
		component: (resolve) => require(['@/views/detail'], resolve),
	},
	{
		path: '/myPrize',
		name: 'myPrize',
		component: (resolve) => require(['@/views/myPrize'], resolve),
	},
]

const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
	mode: 'history',
	routes,
})

export default router
